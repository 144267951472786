.datepicker
{
    top: 0;
    left: 0;

    margin-top: 1px;
    padding: 4px;

    border-radius: 4px;
    &:before
    {
        position: absolute;
        top: -7px;
        left: 6px;

        display: inline-block;

        content: '';

        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-bottom-color: rgba(0, 0, 0, .2);
        border-left: 7px solid transparent;
    }
    &:after
    {
        position: absolute;
        top: -6px;
        left: 7px;

        display: inline-block;

        content: '';

        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-left: 6px solid transparent;
    }
     > div
    {
        display: none;
    }
    table
    {
        width: 100%;
        margin: 0;
    }
    td,
    th
    {
        width: 20px;
        height: 20px;

        text-align: center;

        -webkit-border-radius: 4px;
           -moz-border-radius: 4px;
                border-radius: 4px;
    }
    td
    {
        &.day
        {
            &:hover
            {
                cursor: pointer;

                background: #eee;
            }
            &.disabled
            {
                color: #eee;
            }
        }
        &.old,
        &.new
        {
            color: #999;
        }
        &.active
        {
            color: #fff;
            color: #fff;
            border-color: $yellow $yellow #002a80;
            border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
            background-color: #006dcc;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
            &:hover
            {
                color: #fff;
                color: #fff;
                color: #fff;
                border-color: $yellow $yellow #002a80;
                border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
                background-color: #006dcc;
                background-color: $yellow;
                background-color: #003bb3;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
            }
            &:focus,
            &:hover:focus,
            &:active,
            &:hover:active,
            &.active,
            &:hover.active,
            &.disabled,
            &:hover.disabled,
            &[disabled],
            &:hover[disabled]
            {
                color: #fff;
                background-color: $yellow;
                background-color: #003bb3;
            }
            &:active,
            &:hover:active,
            &.active,
            &:hover.active
            {
                background-color: $yellow;
            }
        }
        span
        {
            line-height: 54px;

            display: block;
            float: left;

            width: 47px;
            height: 54px;
            margin: 2px;

            cursor: pointer;

            border-radius: 4px;
            &:hover
            {
                background: #eee;
            }
            &.active
            {
                color: #fff;
                color: #fff;
                border-color: $yellow $yellow #002a80;
                border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
                background-color: #006dcc;
                &:hover,
                &:focus,
                &:active,
                &.active,
                &.disabled,
                &[disabled]
                {
                    color: #fff;
                    background-color: $yellow;
                }
                &:active,
                &.active
                {
                    background-color: $yellow;
                }
            }
            &.old
            {
                color: #999;
            }
        }
    }
    th
    {
        &.switch
        {
            width: 145px;
        }
        &.next,
        &.prev
        {
            font-size: 21px;
        }
    }
    thead tr:first-child th
    {
        cursor: pointer;
        &:hover
        {
            background: #eee;
        }
    }
}

.input-append.date .add-on i,
.input-prepend.date .add-on i
{
    display: block;

    width: 16px;
    height: 16px;

    cursor: pointer;
}
