// Variables
@import 'variables';
// font-awesome
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700,900');

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/fa-solid.scss';
@import '~@fortawesome/fontawesome-free/scss/fa-brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fa-regular.scss';

// Bootstrap
@import 'node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import 'node_modules/bootstrap-select/sass/bootstrap-select.scss';
@import 'node_modules/owl.carousel/src/scss/owl.theme.default.scss';
@import 'node_modules/owl.carousel/src/scss/owl.carousel.scss';

html
{
    width: 100%;
    height: 100%;
}
body
{
    font-family: 'Montserrat', sans-serif;

    overflow-x: hidden;

    width: 100%;
    height: 100%;

    letter-spacing: .02em;

    color: $dark;
}
// Normalize
h2,
h3
{
    font-size: 32px;
    font-weight: 700;
}

.container,
.container-fluid
{
    position: relative;
}
.btn
{
    @extend .transition;
}
.btn-default
{
    color: $yellow;
    border-color: $yellow;
    background-color: transparent;
    &:hover
    {
        color: $yellow;
        border-color: $dark;
        background-color: $dark;
    }
}
.btn-primary
{
    font-weight: 700;

    height: 56px;
}
.btn-clear
{
    margin: 0;
    padding: 0;

    background-color: transparent;
}
.inline-table
{
    display: inline-table;
}
.flex
{
    display: flex;
}
.longtext
{
    display: inline-block;
    overflow: hidden;

    max-width: 100%;

    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.list-group
{
    margin-bottom: 0;

    list-style: none;
}
.img-responsive
{
    margin: auto;
}
.background-image
{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.transition
{
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}
.transition-slow
{
    -webkit-transition: all .8s ease-in-out;
       -moz-transition: all .8s ease-in-out;
        -ms-transition: all .8s ease-in-out;
         -o-transition: all .8s ease-in-out;
            transition: all .8s ease-in-out;
}
.transform-rotate
{
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
.transform-left-center
{
    -webkit-transform-origin: left center;
       -moz-transform-origin: left center;
         -o-transform-origin: left center;
            transform-origin: left center;
}
.pagination
{
    font-size: 18px;
     > li
    {
        display: inline-block;

        margin: 2px;
        &.active
        {
            a,
            a:hover,
            span,
            span:hover
            {
                color: $yellow;
                border-color: $yellow;
                background-color: transparent;
            }
        }
        &:first-child,
        &:last-child
        {
             > a,
             > span
            {
                border-radius: 0;
            }
        }
         > a,
         > span
        {
            padding: 4px 13px 6px 13px;

            color: $yellow;
        }
    }
}
.bold
{
    font-weight: 700;
}
.small
{
    font-size: 80%;
}
.form-control
{
    height: 40px;

    border-color: $yellow;
    box-shadow: none;
    &:focus
    {
        border-color: $dark-yellow;
        box-shadow: 1px 1px 1px #ccc;
    }
}
.has-error
{
    .form-control
    {
        box-shadow: none;
    }
}
.alert
{
    border-radius: 0;
    a
    {
        text-decoration: underline;

        color: white;
    }
    &.alert-default
    {
        background-color: $light-grey;
    }
}
h2.title
{
    position: relative;

    display: table;

    margin: 80px auto 60px auto;
    padding: 0 30px;

    text-align: center;

    color: $yellow;
    &::before,
    &::after
    {
        position: absolute;
        top: 50%;

        display: block;

        width: 107px;
        height: 4px;
        margin-top: -2px;

        content: '';
    }
    &::before
    {
        left: -107px;

        background: transparent url(../images/title_left.png) no-repeat;
    }
    &::after
    {
        right: -107px;

        background: transparent url(../images/title_right.png) no-repeat;
    }
}
.owl-carousel.owl-theme
{
    .owl-item
    {
        .item-bg
        {
            position: absolute;

            width: 100%;
            height: 100%;

            @extend .background-image;
        }
    }
    .owl-dots
    {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;

        width: 100%;
        .owl-dot
        {
            border: 0;
            background-color: transparent;
            &.active
            {
                span
                {
                    background-color: $yellow;
                }
            }
            span
            {
                margin: 8px 4px;

                border: 1px solid $yellow;
                background-color: transparent;

                @extend .transition;
            }
        }
    }
    .owl-nav
    {
        position: absolute;
        z-index: 2;
        bottom: 50%;
        left: 0;

        width: 100%;
        margin-bottom: -25px;
        button
        {
            font-size: 14px;

            position: absolute;
            bottom: 0;

            width: 50px;
            height: 50px;

            color: white;
            border: 1px solid $yellow;
            border-radius: 0;
            background-color: rgba(0,0,0,.2);

            @extend .transition;

            &:hover
            {
                background-color: $yellow;
            }
            &.owl-prev
            {
                left: 0;
            }
            &.owl-next
            {
                right: 0;
            }
        }
    }
}
.image-hover
{
    &:before
    {
        font-family: 'FontAwesome';

        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        display: grid;
        align-items: center;

        width: 100%;
        height: 100%;

        content: '\f00e';
        text-align: center;

        opacity: 0;
        color: white;
        background-color: rgba(79, 163, 35, .4);

        @extend .transition;
    }
    &:hover
    {
        &:before
        {
            opacity: 1;
        }
    }
}
.content
{
    position: relative;

    min-height:    -moz-calc(100% - 505px); /* Firefox */
    min-height: -webkit-calc(100% - 505px); /* WebKit */
    min-height:      -o-calc(100% - 505px); /* Opera */
    min-height:         calc(100% - 505px); /* Standard */
}
.tabs
{
    .nav-tabs
    {
        margin-bottom: 20px;
         > li
        {
            a
            {
                font-size: 18px;
                font-weight: 700;

                padding-right: 30px;
                padding-left: 30px;

                color: $yellow;
                border-color: $yellow;
                border-radius: 0;

                @extend .transition;
            }
            &.active
            {
                 > a,
                 > a:focus > a:hover
                {
                    color: white;
                    background-color: $yellow;;
                }
            }
        }
    }
}
// /Normalize
header#header
{
    position: absolute;
    z-index: 100;

    width: 100%;
    &.open-menu #nav
    {
        .hamburger
        {
            span
            {
                &:nth-child(1)
                {
                    top: -3px;
                    left: 8px;

                    -webkit-transform: rotate(45deg);
                       -moz-transform: rotate(45deg);
                         -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                }
                &:nth-child(2)
                {
                    width: 0;

                    opacity: 0;
                }
                &:nth-child(3)
                {
                    top: 39px;
                    left: 8px;

                    -webkit-transform: rotate(-45deg);
                       -moz-transform: rotate(-45deg);
                         -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                }
            }
        }
        #main-menu
        {
            z-index: 100;

            opacity: 1;
        }
    }
    #nav
    {
        min-height: 180px;

        background-color: white;
        .brand
        {
            display: flex;
            align-items: center;

            height: 180px;

            vertical-align: middle;
            a
            {
                margin: 0 auto;

                text-align: center;
                img
                {
                    height: 130px;
                }
            }
        }
        .nav
        {
            display: inline-block;

            padding-top: 65px;
             > li
            {
                display: inline-block;

                margin-right: 30px;
                &:last-child
                {
                    margin-right: 0;
                }
                 > a
                {
                    font-size: 14px;

                    margin: 0;
                    padding-right: 0;
                    padding-left: 0;

                    color: $dark;

                    @extend .transition;
                    &.active,
                    &:hover,
                    &:focus
                    {
                        color: $yellow;
                        background-color: transparent;
                    }
                }
            }
        }
        .hamburger
        {
            display: none;

            width: 60px;
            height: 45px;

            cursor: pointer;
            -webkit-transform: scale(.5);
               -moz-transform: scale(.5);
                -ms-transform: scale(.5);
                 -o-transform: scale(.5);
                    transform: scale(.5);

            @extend .transform-rotate;
            @extend .transition;
            span
            {
                position: absolute;
                left: 0;

                display: block;

                width: 100%;
                height: 9px;

                opacity: 1;
                border-radius: 9px;
                background: $dark;

                @extend .transform-rotate;
                @extend .transition;
                &:nth-child(1)
                {
                    top: 0;

                    @extend .transform-left-center;
                }
                &:nth-child(2)
                {
                    top: 18px;

                    @extend .transform-left-center;
                }

                &:nth-child(3)
                {
                    top: 36px;

                    @extend .transform-left-center;
                }
            }
        }
    }
}

#contact
{
    margin-top: 80px;
    padding-left: 0;

    background-color: #f0f0f0;
    #block-maps
    {
        float: left;

        width: 100%;
        height: 600px;
    }
    .col-md-4
    {
        padding: 50px;

        color: $yellow;
        input,
        textarea
        {
            height: 42px;

            background-color: white;
            &.textarea
            {
                height: 100px;
            }
        }
        .btn
        {
            font-weight: 700;

            padding: 15px 20px;
        }
    }
}
footer
{
    padding-top: 80px;

    color: white;
    background-color: #1a1a1a;
    a
    {
        color: white;

        @extend .transition;
    }
    i
    {
        margin-right: 5px;

        color: $yellow;
    }
    .menu
    {
        a
        {
            color: $yellow;
            &:hover
            {
                color: $dark-yellow;
            }
        }
    }
    .contact-info
    {
        font-size: 16px;
    }
    .copy
    {
        font-size: 12px;

        padding: 60px 0 20px 0;

        color: $yellow;
        a
        {
            color: $yellow;
            &:hover
            {
                color: $dark-yellow;
            }
        }
    }
    #back-to-top
    {
        line-height: 14px;

        position: fixed;
        z-index: 9999;
        right: 40px;
        bottom: 40px;

        width: 32px;
        height: 32px;

        opacity: 0;

        @extend .transition;
        &:focus
        {
            color: $yellow;
            background: $dark;
        }
        &.show
        {
            opacity: 1;
        }
    }
}

#home
{
    #home-slider.owl-theme
    {
        .owl-item
        {
            height: 700px;
            &.active
            {
                .banner
                {
                    span.text
                    {
                        opacity: 1;
                    }
                }
            }
            .banner
            {
                position: relative;

                display: flex;
                align-items: center;

                width: 100%;
                height: 100%;
                @extend.background-image;
                span.text
                {
                    font-size: 18px;

                    z-index: 1;

                    width: 100%;
                    margin-top: -200px;

                    opacity: 0;
                    color: white;

                    @extend .transition-slow;
                    h4
                    {
                        font-size: 60px;
                    }
                }
            }
        }
    }
    #banners-info
    {
        position: relative;

        height: 100px;
        .itens
        {
            position: absolute;
            z-index: 100;
            top: -60px;

            width: 100%;
            min-height: 150px;

            background: white;
            box-shadow: 0 5px 10px -5px #a1a1a1;
            .col-md-3
            {
                height: 150px;

                border-right: 1px solid $light-grey;
                &:last-child
                {
                    border-right: 0;
                }
                span
                {
                    display: flex;
                    align-items: center;

                    height: 75px;
                    margin-top: 20px;
                    margin-bottom: 5px;
                    img
                    {
                        margin: auto;
                    }
                }
            }
        }
    }
    #types
    {
        .item
        {
            background-color: #f7f3ea;
            .cover
            {
                height: 600px;
            }
            .info
            {
                padding: 80px 60px 0 60px;
                &.right
                {
                    float: right;
                }
                &.left
                {
                    text-align: right;
                }
                h3
                {
                    font-size: 36px;

                    margin-bottom: 60px;

                    color: $yellow;
                    small
                    {
                        font-size: 14px;
                        font-weight: 700;

                        display: block;

                        color: $yellow;
                    }
                }
                .resum
                {
                    font-size: 18px;
                }
                .btn
                {
                    margin-top: 60px;
                    padding: 15px 20px;
                }
            }
        }
    }
    .pages
    {
        padding: 0;
        .item,
        .event
        {
            display: flex;
            align-items: center;

            background-color: $light-grey;
            .info
            {
                h3
                {
                    font-size: 22px;

                    color: $yellow;
                }
            }
            &.event
            {
                height: 400px;
                .pages-slider
                {
                    .owl-item
                    {
                        height: 400px;
                    }
                }
                .info
                {
                    padding: 50px 50px 50px 80px;
                }
            }
        }
        .pages-slider
        {
             > .owl-dots
            {
                bottom: -50px;
            }
            .owl-item
            {
                align-items: center;

                height: 500px;
                .image
                {
                    height: 100%;
                }
                .info
                {
                    padding: 50px 80px 50px 50px;
                }
            }
        }
    }
}
#reservation
{
    margin-bottom: 100px;
    form
    {
        .input-group
        {
            .form-control
            {
                padding-right: 5px;
                padding-left: 5px;

                border-left: 0;
            }
            .input-group-addon
            {
                font-weight: 600;

                padding-right: 5px;

                color: $yellow;
                border-color: $yellow;
                background-color: white;
            }
        }
        label
        {
            font-weight: 400;
        }
        .btn-default
        {
            height: 40px;
            margin-left: 0;

            border-color: $yellow;
        }
    }
}
#pages,
#about,
#types,
#reservations,
#infra,
#error-page
{
    .header
    {
        height: 380px;
        margin-bottom: 60px;

        border-bottom: 9px solid #b89e5e;
        background-image: url(../images/bg_header.jpg);

        @extend .background-image;
        h2
        {
            font-size: 36px;

            margin-top: 280px;

            color: white;
        }
    }
    h3.subtitle
    {
        font-size: 34px;
        font-weight: 700;
        font-style: italic;

        position: relative;
        z-index: 2;

        padding: 50px 40px 30px 40px;

        color: $yellow;
        span
        {
            z-index: 10;
        }
    }
    &#about
    {
        h3.subtitle
        {
            &::before,
            &::after
            {
                position: absolute;
                z-index: -1;

                display: block;

                width: 100px;
                height: 83px;

                content: '';
            }
            &::before
            {
                top: 0;
                left: 0;

                background: transparent url(../images/comma_left.png) no-repeat;
            }
            &::after
            {
                right: 0;
                bottom: 0;

                background: transparent url(../images/comma_right.png) no-repeat;
            }
        }
        .info
        {
            font-size: 16px;

            padding-right: 40px;
            padding-left: 40px;
        }
        .image
        {
            height: 500px;
        }
        .subpage
        {
            margin-top: 40px;
            margin-bottom: -80px;
            padding-top: 40px;
            padding-bottom: 100px;

            background-color: $light-grey;
        }
    }
    &#types
    {
        .tab-pane
        {
            .images
            {
                padding-top: 70px;
            }
            .info
            {
                padding: 40px 60px 0 60px;
                h3
                {
                    font-size: 36px;

                    margin-bottom: 20px;

                    color: $yellow;
                    small
                    {
                        font-size: 14px;
                        font-weight: 700;

                        display: block;

                        color: $yellow;
                    }
                }
                 > ul
                {
                    font-size: 18px;

                    padding-left: 18px;
                }
                .btn
                {
                    margin-top: 20px;
                    padding: 15px 20px;
                }
            }
        }
    }
    &#infra
    {
        #banners-info
        {
            .info
            {
                .col-md-6
                {
                    height: 200px;

                    border: 1px solid $light-grey;
                    span
                    {
                        display: flex;
                        align-items: center;

                        height: 75px;
                        margin-top: 50px;
                        margin-bottom: 5px;
                        img
                        {
                            margin: auto;
                        }
                    }
                }
            }
            .image
            {
                height: 400px;
            }
        }

        h4
        {
            font-size: 18px;
            font-weight: 700;

            padding-bottom: 10px;

            color: $yellow;
        }
        .gallery
        {
            .image
            {
                height: 200px;
                a
                {
                    position: relative;

                    display: block;

                    height: 100%;
                    margin-right: -15px;
                    margin-left: -15px;
                    &:hover
                    {
                        i
                        {
                            opacity: 1;
                        }
                    }
                    i
                    {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;

                        display: grid;
                        align-items: center;

                        width: 100%;
                        height: 100%;

                        text-align: center;

                        opacity: 0;
                        color: #fff;
                        background-color: rgba(0,0,0,.4);

                        @extend .transition;
                    }
                }
            }
        }
    }
}

@import 'app-date';
@import 'app-responsive';
