// Responsive
@import 'variables';
@media (max-width: $screen-sm)
{
    h2
    {
        font-size: 45px;
    }
    .collapse-xs
    {
        display: none;
    }
    .nav-tabs
    {
         > li
        {
            float: none;

            text-align: center;
            a
            {
                border-right: 0;
            }
        }
    }
    header#header
    {
        background-color: white;
        &.open-men #main-menu
        {
            z-index: 100;

            opacity: 1;
        }
        div.bg-orange
        {
            height: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            .pull-left
            {
                display: none;
            }
        }
        #nav
        {
            position: relative;
            .brand
            {
                height: 80px;
            }
            .nav
            {
                padding-top: 0;
            }
            .hamburger
            {
                display: inline-block !important;
            }
            #main-menu
            {
                position: absolute;
                z-index: -1;
                top: 51px;
                left: 0;

                width: 100%;
                padding: 0 20px;

                -webkit-transition: all .2s ease-in-out;
                   -moz-transition: all .2s ease-in-out;
                    -ms-transition: all .2s ease-in-out;
                     -o-transition: all .2s ease-in-out;
                        transition: all .2s ease-in-out;

                opacity: 0;
                border: 2px solid #4fa323;
                background-color: white;
                box-shadow: 2px 2px 2px hsla(0, 0%, 0%, .3);
                li
                {
                    display: block;
                }
            }
        }
        .col-md-3
        {
            .brand
            {
                height: 85px;
                padding-top: 15px;
                &::before
                {
                    content: none;
                }
                img.img-responsive
                {
                    height: 70px;
                }
            }
            .right-menu
            {
                height: 65px;
                padding-top: 10px;

                background-color: #f0f0f0;
            }
        }
        .col-md-6
        {
            padding: 0;
            nav
            {
                overflow: hidden;

                height: 0;
                margin-top: 20px;

                -webkit-transition: all .2s ease-in-out;
                   -moz-transition: all .2s ease-in-out;
                    -ms-transition: all .2s ease-in-out;
                     -o-transition: all .2s ease-in-out;
                        transition: all .2s ease-in-out;
                transform: none;

                border-bottom: 0;
                background: $grey;

                &.open
                {
                    height: 160px;
                }

                ul.menu
                {
                    margin-bottom: 0;
                    padding: 0;
                    li
                    {
                        display: block;
                        a
                        {
                            display: block;

                            padding: 5px 0;

                            border-bottom: 1px solid $light-grey;
                        }
                        &:last-child
                        {
                            a
                            {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    #home
    {
        #home-slider.owl-theme
        {
            .owl-item
            {
                .banner
                {
                    span.text
                    {
                        h4
                        {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
        #banners-info
        {
            .item
            {
                .owl-carousel.owl-theme
                {
                    .owl-item
                    {
                        min-height: 200px;
                        .col-md-6
                        {
                            height: 240px;

                            &:first-child
                            {
                                display: none;
                            }
                            .info
                            {
                                padding: 0 20px;
                                h3
                                {
                                    font-size: 26px;
                                }
                            }
                        }
                    }
                }
            }
        }
        #products-rand,
        #products-kits
        {
            padding-right: 15px;
            padding-left: 15px;
            &#products-kits
            {
                .col-md-4
                {
                    height: 400px;
                }
            }
            &#products-rand
            {
                .mixitup-categories
                {
                    button
                    {
                        font-size: 12px;

                        padding: 10px;
                    }
                }
                .mixitup-page-list,
                .mixitup-sort
                {
                    text-align: center;
                }
            }
        }
    }
    #products-show
    {
        h2.title
        {
            font-size: 22px;
        }
        .images
        {
            .gallery
            {
                .owl-nav
                {
                    .owl-prev
                    {
                        left: 0 !important;
                    }
                    .owl-next
                    {
                        right: 0 !important;
                    }
                }
            }
        }
        .owl-dots
        {
            display: none;
        }
        .tabs .tab-content
        {
            padding: 10px 20px 0 20px;
        }
    }
    #products
    {
        .col-xs-12
        {
            margin-bottom: 20px;
            padding: 0;
        }
        .col-md-3
        {
            &#sidebar
            {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    #sidebar
    {
        h3
        {
            font-size: 18px;

            margin: 0 0 10px 0;
            padding-bottom: 8px;
        }
         > .row
        {
             > .col-md-12
            {
                padding: 20px;
            }
        }
    }

    #checkout
    {
        .cart-list
        {
            ul.list-group
            {
                li.product
                {
                    display: list-item;

                    padding: 10px;

                    text-align: center;
                    .image
                    {
                        margin: 0 auto;
                    }
                    .text-right
                    {
                        text-align: center;
                    }
                }
            }
            .totals
            {
                .col-md-10
                {
                    text-align: center;
                }
            }
        }
        .shipping
        {
            text-align: center;

            border: 1px solid $light-grey;
            label
            {
                margin: 10px 0;
            }
            label,
            .form-control
            {
                width: 100%;

                text-align: center;
            }
            .shipping-value
            {
                margin: 10px 0;
            }
        }
        .btn-submit,
        .btn-close
        {
            margin-bottom: 10px;
        }
    }
    .modal-body
    {
        #checkout
        {
            .shipping
            {
                border-color: transparent;
            }
        }
    }
    #newsletter
    {
        .container
        {
            -webkit-transform: none;
                    transform: none !important;
            .bg-orange,
            .bg-blue
            {
                height: 130px;
            }
        }
    }
    footer
    {
        #back-to-top
        {
            right: 0;
            bottom: 0;
        }
        .logo
        {
            img
            {
                margin: 0 auto 40px auto;
            }
        }
        h3
        {
            margin-top: 40px;
            padding-bottom: 20px;
            &::before
            {
                bottom: 5px;
            }
        }
        .contact
        {
            padding: 20px 0;
            i
            {
                width: 55px;
                height: 55px;
                margin-bottom: 10px;
                padding-top: 8px;
            }
            .social
            {
                a
                {
                    display: block;

                    margin-bottom: 5px;
                }
            }
        }
        .copy
        {
            text-align: center;
            .pull-right
            {
                float: inherit !important;
            }
        }
    }
}
// /Responsive
